import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Divider,
} from "@material-ui/core";
import { graphql } from "gatsby";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    background: "#09333F",
    backgroundSize: "100% 100%",
  },
  subtitle: {
    margin: "1rem",
    fontWeight: "bold",
  },
  text: {
    margin: "1rem",
  },
  title: {
    marginTop: "1rem",
  },
  textPartTwo: {
    marginTop: "2rem",
    paddingBottom: "2rem",
  },
  listItems: {},
  link: {
    marginBottom: "1rem",
    color: "#fff",
    textDecoration: "none",
  },
  btn: {
    color: "#fff",
  },
  list: {
    listStyle: "disc",
  },
}));

const Success = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navbar />
      <Typography
        variant="h2"
        gutterBottom
        className={classes.hero}
        align="center"
      >
        Das sagen unsere Kunden
      </Typography>
      <Container maxWidth="md">
        <Grid container>
          {kunden.map((kunde) => (
            <Grid item>
              <Typography gutterBottom variant="h5" className={classes.title}>
                {kunde.title}
              </Typography>
              <Typography gutterBottom variant="body1" className={classes.text}>
                {kunde.text}
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                className={classes.subtitle}
              >
                {kunde.subtitle}
              </Typography>
              <Divider variant="middle" />
            </Grid>
          ))}
          <Grid item className={classes.textPartTwo}>
            <Typography variant="body1" gutterBottom>
              M3E Communications hat bereits erfolgreich in folgenden Medien
              online, gedruckt, als Bewegtbild- und Audiobeiträge Interviews,
              Podcasts, Fachartikel und Berichterstattungen veröffentlicht und
              steht mit den Redaktionen in regelmäßigem Austausch:
            </Typography>

            {lists.map((ListItem) => (
              <Container maxWidth="md">
                <Grid item xs={12} className={classes.listItems}>
                  <ul className={classes.list}>
                    <li>
                      <a
                        href={ListItem.url}
                        target="_blank"
                        className={classes.link}
                      >
                        {ListItem.title}
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Container>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Success;

const kunden = [
  {
    title: "ACE",
    text: '"Mit M3E haben wir einen ausgezeichneten Partner gefunden, der uns bei der Betreuung unseres Facebook Accounts zuverlässig zur Seite steht. Die Kompetenz im Bereich Mobilität und die Affinität zu Themen der Elektromobilität ermöglichen uns ein zielgerichtetes Community Management. Durch das hohe Maß an Engagement seitens des M3E-Teams konnten wir gemeinsam unsere Reichweite steigern."',
    subtitle:
      "Anja Smetanin, Abteilungsleiterin Marketing und Vertrieb, ACE Auto Club Europa e.V.",
  },
  {
    title: "Parkstrom",
    text: '"M3E überzeugt mit hervorragender Branchenkenntnis und Vernetzung im Bereich Elektromobilität. Unsere Zusammenarbeit im Bereich Presse- und Öffentlichkeitsarbeit ist geprägt von viel Engagement und daraus resultierend sehr guten Ergebnissen."',
    subtitle: "Stefan Pagenkopf-Martin, Geschäftsführer Parkstrom GmbH",
  },
  {
    title: "Swobbee",
    text: '"M3E bietet uns genau das, was wir als aufstrebendes Startup brauchen: super PR-Arbeit. Dank kompetenter Beratung und flexiblem Service können wir unsere Öffentlichkeitsarbeit gezielt steuern und schnell auf sich wandelnde Kommunikationsanforderungen reagieren."',
    subtitle: "Tobias Breyer, COO & Co-Founder Swobbee",
  },
  {
    title: "Donkey Republic",
    text: '"Christian und Sascha von der M3E waren äußerst zuverlässig und gaben während unserer gesamten Partnerschaft konstruktives Feedback. Sie haben uns geholfen, online ein positives Markenbewusstsein zu schaffen, insbesondere bei auf Mobilität fokussierten Medien. Wir hatten eine sehr angenehme Erfahrung in der Zusammenarbeit mit ihnen."',
    subtitle: "Jade Devey, International PR Manager, Donkey Republic",
  },
];

const lists = [
  { title: "RTL", url: "https://www.rtl.de/" },
  { title: "FOCUS", url: "https://www.focus.de/" },
  { title: "Der Tagesspiegel", url: "https://www.tagesspiegel.de/" },
  { title: "Auto, Motor, Sport", url: "https://www.auto-motor-und-sport.de/" },
  { title: "rbb InfoRadio", url: "https://www.inforadio.de/" },
  { title: "Berliner Morgenpost", url: "https://www.morgenpost.de/" },
  { title: "BFP", url: "https://www.bfp.de/" },
  { title: "Bundersverband eMobilität e.V.", url: "https://www.bem-ev.de/" },
  { title: "haustec.de", url: "https://www.haustec.de/" },
  { title: "Energyload", url: "https://energyload.eu/" },
  { title: "golem.de", url: "https://www.golem.de/" },
  { title: "Berliner Zeitung", url: "https://www.berliner-zeitung.de/" },
  { title: "electrive.net", url: "https://www.electrive.net/" },
  {
    title: "Energie & Management",
    url: "https://www.energie-und-management.de/nachrichten/alle/",
  },
  { title: "Autohaus", url: "https://www.autohaus.de/" },
  { title: "PV", url: "https://www.pv-magazine.de/" },
  {
    title: "Automobil Industrie",
    url: "https://www.automobil-industrie.vogel.de/",
  },
  { title: "ingenieur.de", url: "https://www.ingenieur.de/" },
  { title: "energate messenger", url: "https://www.energate-messenger.de/" },
  { title: "Edisol", url: "https://edisolonline.com/" },
];

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
